import { memo, useState, useEffect, useContext, useImperativeHandle } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import isEqual from 'src/utils/common/isEqual';
import PropTypes from 'prop-types';
import {
	AppBar,
	Avatar,
	Badge,
	Box,
	Button,
	IconButton,
	Menu,
	Link,
	// MenuItem,
	// ListItemText,
	Toolbar,
	makeStyles,
	Typography,
	withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@material-ui/icons/Menu';
import cn from 'classnames';

import { Logo, Share, DialogBox } from 'src/components/ui';
import { EventEmitter } from 'src/helpers/EventEmitter';
import { fetchFromStorage, saveToStorage, clearStorage } from 'src/helpers/context';
import UserPlaceholderIcon from 'src/assets/images/icons/user-placeholder.svg';
import { ReactComponent as NotificationIcon } from 'src/assets/images/icons/notification.svg';
import { ReactComponent as MobileTopSlogan } from 'src/assets/images/slogan.svg';
// import { Config } from 'src/helpers/context/config';
// import { identifiers } from 'src/helpers/constants/identifier';
import { ViewPortContext } from 'src/App';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import { AddEditOrg } from 'src/pages/application/Organization/AddEditOrg';

import items from '../menu';
import styles from './TopBar.module.scss';
import { useLayoutStyles } from '../../components';

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #2e353a',
		background: '#171717',
		marginTop: 15,
		borderRadius: '3px',
		boxShadow: '',
		color: '#fff',
		paddingLeft: 10,
		paddingRight: 28,
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
));

const useStyles = makeStyles((theme) => ({
	root: { backgroundColor: '#151515' },

	mobileToolBar: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px',
	},
	title: {
		marginRight: 'auto',
	},
	avatar: { width: 36, height: 36, cursor: 'pointer' },
}));

export const TopBar = ({
	auth,
	user_statics,
	verify_count,
	className,
	onMobileNavOpen,
	onRef,
	children,
	logoutUser,
	...rest
}) => {
	const { t } = useTranslation();
	const viewPortContext = useContext(ViewPortContext);
	const windowWidth = viewPortContext?.width;
	const breakPoint = viewPortContext?.breakPoint;

	const layoutClasses = useLayoutStyles()();
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const user_id = auth ? auth?.user_id : fetchFromStorage('authData')?.user_id;
	const isAdmin = auth ? auth?.isAdmin : fetchFromStorage('authData')?.isAdmin;
	const [avt, setAvt] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
	const [opens, setOpen] = useState(false);
	const [organisationData, setOrganisation] = useState([]);
	const [modal, setModal] = useState('');
	const [orgData, setOrgData] = useState([]);

	const token = useSelector((state) => state?.Combat?.authData)?.token || fetchFromStorage('authData')?.token;

	const headers = {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};

	useImperativeHandle(onRef, () => ({
		setOpenDialog: () => {
			setOpen(true);
		},
	}));

	var USER_DATA = fetchFromStorage('authData') !== null && fetchFromStorage('authData')?.user_meta;

	let USER_ID = USER_DATA !== undefined ? USER_DATA?.data : user_id;
	let USERID = USER_ID !== '' ? USER_ID : fetchFromStorage('authData')?.user_id;

	//  TO OPEN USER ACTION DIALOG
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const setAvatarChangeValue = (val) => {
		setAvt(val);
		var userData = fetchFromStorage('authData');
		let authData = { ...userData, profile_pic: val };

		saveToStorage('authData', authData);
	};

	const combatDialogHandler = (title) => {
		setOpen(true);
	};
	const closeDialog = () => {
		setOpen(false);
	};

	useEffect(() => {
		EventEmitter.subscribe('user_image_change', setAvatarChangeValue);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (auth?.profile_pic !== '') {
			setAvt(auth?.profile_pic);
		}
		// eslint-disable-next-line
	}, [auth?.profile_pic]);

	const fetchMyOrganisation = async () => {
		try {
			let { status, data } = await axiosInstance.get(URLS.getMyOrganisations + `?page=${1}&limit=${2}`, headers);

			if (status === 200) {
				setOrganisation(data?.orgData);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};

	const handleNewOrg = () => {
		setOpen(true);
		setModal('newOrg');
	};

	const handleUpdate = (orgData) => {
		setOpen(true);
		setModal('updateOrg');
		// setTitle(t('organisation.edit_organisation'));
		setOrgData(orgData);
	};

	useEffect(() => {
		fetchMyOrganisation();
		setModal('');
	}, []);

	return (
		<AppBar
			className={
				isAdmin
					? `${clsx(classes.root, className)} ${styles['admin-app-bar']}`
					: `${clsx(classes.root, className)} ${styles['app-bar']}`
			}
			elevation={0}
			{...rest}
		>
			{windowWidth > breakPoint ? (
				<>
					<Toolbar>
						<NavLink to="/news-feed" className={styles['logo-anchor']}>
							<Logo className={classes.logo} />
							{/* <Hidden lgUp>
								{auth ? (
								  <Typography className={cn(styles['cm-user-title'], styles['show-md'])}>
									CM {t("search.users")}:&nbsp;<span>{user_statics?.verify_user_count}</span>
								  </Typography>
								) : verify_count !== undefined ? (
								  verify_count !== null ? (
									<Typography className={cn(styles['cm-user-title'], styles['show-md'])}>
									  CM {t("search.users")}:&nbsp;
									  <span>
										{verify_count !== undefined
										  ? verify_count !== null
											? verify_count
											: null
										  : null}
									  </span>
									</Typography>
								  ) : null
								) : null}
							  </Hidden> */}
						</NavLink>

						{/* <Hidden lgDown> */}
						<Box className={styles['app-bar-cm-user']}>
							{auth ? (
								<Typography className={styles['cm-user-title']}>
									CM {t('top_menu_options.users')}:&nbsp;<span>{user_statics?.verify_user_count}</span>
								</Typography>
							) : verify_count !== undefined ? (
								verify_count !== null ? (
									<Typography className={styles['cm-user-title']}>
										CM {t('top_menu_options.users')}:&nbsp;
										<span>{verify_count !== undefined ? (verify_count !== null ? verify_count : null) : null}</span>
									</Typography>
								) : null
							) : null}
						</Box>
						{/* </Hidden> */}

						<Box flexGrow={1} />
						{/* MENU BUTTONS */}
						{items.length > 0 &&
							items.map((menu, index) => {
								// let menuPath =
								//   menu.href === '/events/discover' ? '/events' : menu?.href;
								return (
									<>
										{menu.href === '/events/discover' ? (
											<NavLink
												to={menu.href}
												className={
													location.pathname.includes(menu.href)
														? `${layoutClasses.button} ${layoutClasses.buttonActive} ${styles['app-menu-btn']}`
														: `${layoutClasses.button} ${styles['app-menu-btn']}`
												}
											>
												{t(menu.title)}
											</NavLink>
										) : (
											<Button
												key={index}
												className={
													location.pathname.includes(menu.href)
														? `${layoutClasses.button} ${layoutClasses.buttonActive} ${styles['app-menu-btn']}`
														: `${layoutClasses.button} ${styles['app-menu-btn']}`
												}
												component={NavLink}
												to={menu.href}
												disableElevation
												disableFocusRipple
												disableRipple
											>
												<span className={classes.title}>{t(menu.title)}</span>
											</Button>
										)}
									</>
								);
							})}

						{/* ADMIN DASHBOARD */}
						{isAdmin && (
							<Button
								className={`${layoutClasses.button} ${styles['app-menu-btn']}`}
								component={NavLink}
								to="/admin/dashboard"
								disableElevation
								disableFocusRipple
								disableRipple
							>
								<span className={classes.title}>{t('nav_menu_options.dashboard')}</span>
							</Button>
						)}

						{/* SEARCH BUTTON */}

						{/*HIDE-MVP: CM-78*/}
						{/*{auth ? (*/}
						{/*	<IconButton*/}
						{/*		className={styles['app-bar-searchbtn']}*/}
						{/*		onClick={() => {*/}
						{/*			navigate('/search');*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		<SearchIcon />*/}
						{/*	</IconButton>*/}
						{/*) : (*/}
						{/*	<Box width={'36px'} className={styles['app-bar-searchbtn']} />*/}
						{/*)}*/}

						{/* NOTIFICATION BUTTON */}
						{auth ? (
							<IconButton
								className={styles['app-bar-notification-btn']}
								onClick={() => {
									navigate('/notification');
								}}
							>
								<Badge badgeContent={user_statics?.unreadNotification} color="error">
									<NotificationIcon />
								</Badge>
							</IconButton>
						) : (
							<Box width={'31.27px'} />
						)}
						{/* MESSAGES */}
						{/* HIDE-MVP: CM-76 */}
						{/*{auth ? (*/}
						{/*	<IconButton*/}
						{/*		className={cn(styles['app-bar-notification-btn'], styles['message-icon'])}*/}
						{/*		onClick={() => {*/}
						{/*			navigate('/messages');*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		{user_statics?.unreadMessages > 0 ? (*/}
						{/*			<Badge badgeContent={user_statics?.unreadMessages} color="error">*/}
						{/*				<MsgIcon className={styles['msg-icon']} />*/}
						{/*			</Badge>*/}
						{/*		) : (*/}
						{/*			<MsgIcon className={styles['msg-icon']} />*/}
						{/*		)}*/}
						{/*	</IconButton>*/}
						{/*) : (*/}
						{/*  <Box width={'36px'} />*/}
						{/*)}*/}

						{/* USER IMAGE */}
						{auth ? (
							<Avatar
								src={avt ? avt : UserPlaceholderIcon}
								className={classes.avatar}
								onClick={handleClick}
								// onClick={() => {
								//   // removeFromStorage(identifiers.visitorUsers);
								//   navigate(`/user/${USERID}`);
								// }}
							/>
						) : (
							<Box width={'36px'} />
						)}

						{/* USER ACTION MODAL */}
						<StyledMenu
							id="customized-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
							className={styles['customized-menu-class']}
						>
							{/* <MenuItem
							  className={styles['msg-icon']}
							  onClick={() => {
								navigate(`/user/${USERID}`);
								handleClose();
							  }}
							>
							  <ListItemText primary="My Profile" />
							</MenuItem>
							<MenuItem
							  className={styles['my-option-menu']}
							  onClick={() => {
								clearStorage();
								window.location = '/';
								handleClose();
							  }}
							>
							  <ListItemText primary="Log Out" />
							</MenuItem> */}
							{/*<Box className={styles['user-action-modalbody']}>*/}
							{/*	HIDE-MVP: CM-79*/}
							{/*	<Box className={styles['modal-h']}>*/}
							{/*		<Typography>Organisations</Typography>*/}
							{/*		<Button*/}
							{/*			disableElevation*/}
							{/*			disableRipple*/}
							{/*			disableTouchRipple*/}
							{/*			disableFocusRipple*/}
							{/*			className={styles['seeall-btn']}*/}
							{/*			onClick={() => {*/}
							{/*				navigate('/your-organisation');*/}
							{/*			}}*/}
							{/*		>*/}
							{/*			See All*/}
							{/*		</Button>*/}
							{/*	</Box>*/}
							{/*	ORGANISATION LIST HIDE-MVP: CM-79*/}
							{/*	{organisationData.map((org, i) => {*/}
							{/*		return (*/}
							{/*			<Box key={i} className={styles['org-item']}>*/}
							{/*				<Box className={styles['org-data']}>*/}
							{/*					<Avatar className={styles['org-img']} src={org?.item_profile_id} />*/}
							{/*					<Typography*/}
							{/*						// TODO: [STYLE] delete global style*/}
							{/*						className="org-name"*/}
							{/*						onClick={() => {*/}
							{/*							navigate(`/organisation-details/${org?.id}`);*/}
							{/*						}}*/}
							{/*					>*/}
							{/*						{org?.name}*/}
							{/*					</Typography>*/}
							{/*				</Box>*/}

							{/*				<IconButton*/}
							{/*					onClick={() => {*/}
							{/*						handleUpdate(org);*/}
							{/*					}}*/}
							{/*				>*/}
							{/*					<EditIcon />*/}
							{/*				</IconButton>*/}
							{/*			</Box>*/}
							{/*		);*/}
							{/*	})}*/}
							{/*	HIDE-MVP: CM-79*/}
							{/*	<Button*/}
							{/*		startIcon={<BAddIcon />}*/}
							{/*		variant="contained"*/}
							{/*		fullWidth*/}
							{/*		className={styles['neworg-btn']}*/}
							{/*		onClick={() => handleNewOrg()}*/}
							{/*	>*/}
							{/*		New Organisation*/}
							{/*	</Button>*/}
							<Box className={styles['modal-h']}>
								<NavLink to={`/user/${USERID}`} className={cn(styles['seeall-btn'], styles['wbtn'])}>
									{t('nav_menu_options.my_profile')}
								</NavLink>
								{/* <Button
										  disableElevation
										  disableRipple
										  disableTouchRipple
										  disableFocusRipple
										  className={cn(styles['seeall-btn'], styles['wbtn'])}
										  component={NavLink}
										  to={`/user/${USERID}`}
										  onClick={handleClose}
										>
										  My Profile
									</Button> */}
							</Box>
							{/* HIDE-MVP: CM-72 */}
							{/*<Box className={styles['modal-h']}>*/}
							{/*	<Link href={`/claim-profile`} className={cn(styles['seeall-btn'], styles['wbtn'])}>*/}
							{/*		LINKED PROFILES*/}
							{/*	</Link>*/}
							{/*</Box>*/}
							<Box className={styles['modal-h']}>
								<NavLink to={`/referral-code`} className={cn(styles['seeall-btn'], styles['wbtn'])}>
									{t('nav_menu_options.my_referrals')}
								</NavLink>
								{/* <Button
										  disableElevation
										  disableRipple
										  disableTouchRipple
										  disableFocusRipple
										  className={cn(styles['seeall-btn'], styles['wbtn'])}
										  component={NavLink}
										  to={`/referral-code`}
										  onClick={handleClose}
										>
										  MY REFERRALS
										</Button> */}
							</Box>
							<Box className={styles['modal-h']}>
								<Button
									disableElevation
									disableRipple
									disableTouchRipple
									disableFocusRipple
									className={cn(styles['seeall-btn'], styles['wbtn'])}
									// component={NavLink}
									// to={`/user/${USERID}`}
									// onClick={handleClose}
									onClick={() => {
										setModal('');
										combatDialogHandler(t('community.share'));
									}}
								>
									{t('nav_menu_options.share_your_profile')}
								</Button>
							</Box>
							<Box className={styles['modal-h']}>
								<Button
									disableElevation
									disableRipple
									disableTouchRipple
									disableFocusRipple
									className={cn(styles['seeall-btn'], styles['wbtn'])}
									onClick={() => {
										navigate('/Account-settings');
									}}
								>
									{t('nav_menu_options.account_setting')}
								</Button>
							</Box>
							<Box className={styles['modal-h']}>
								<Button
									disableElevation
									disableRipple
									disableTouchRipple
									disableFocusRipple
									className={cn(styles['seeall-btn'], styles['wbtn'])}
									// component={NavLink}
									// to={`/user/${USERID}`}
									// onClick={handleClose}
									onClick={() => {
										logoutUser();
										clearStorage();
										window.location = '/';
									}}
								>
									{t('nav_menu_options.logout')}
								</Button>
							</Box>
						</StyledMenu>
						{/* </Hidden> */}
						{/* <Hidden lgUp>
						  {auth ? (
							<IconButton
							  // className="app-bar-searchbtn"
							  onClick={() => {
								navigate('/search');
							  }}
							>
							  <SearchIcon />
							</IconButton>
						  ) : (
							<Box width={'36px'} className="app-bar-searchbtn" />
						  )}
						</Hidden> */}
						{/* <Hidden lgUp >
						  <IconButton color="inherit" onClick={onMobileNavOpen}>
							<MenuIcon />
						  </IconButton>
						</Hidden> */}
						{!auth && (
							<Button
								className="auth-login-button"
								variant="contained"
								onClick={() => {
									navigate('/sign-in');
								}}
							>
								{t('login.signin')}
							</Button>
						)}
					</Toolbar>
				</>
			) : (
				<>
					{/* when the phone size is mobile size, the toolbar will change */}
					<Toolbar className={classes.mobileToolBar}>
						<IconButton color="inherit" className={classes.mobileMenuIcon} onClick={onMobileNavOpen}>
							<MenuIcon />
						</IconButton>
						{/*HIDE-MVP: CM-78*/}
						{/*add style to center navlink*/}
						<NavLink to="/news-feed" style={{ margin: '0 auto' }}>
							<MobileTopSlogan />
						</NavLink>
						{/*HIDE-MVP: CM-78*/}
						{/*<Box>*/}
						{/*	{auth ? (*/}
						{/*		<IconButton*/}
						{/*			onClick={() => {*/}
						{/*				navigate('/search');*/}
						{/*			}}*/}
						{/*		>*/}
						{/*			<SearchIcon />*/}
						{/*		</IconButton>*/}
						{/*	) : (*/}
						{/*		<Box width={'36px'} className="app-bar-searchbtn" />*/}
						{/*	)}*/}
						{/*</Box>*/}
					</Toolbar>
				</>
			)}
			{/*<Box className={styles['site-banner']}>*/}
			{/*	<Typography className={styles['banner-title']}>*/}
			{/*		{Config?.DomainName}*/}
			{/*		{t('banner_text')}*/}
			{/*	</Typography>*/}
			{/*</Box>*/}
			<DialogBox
				className={modal === 'newOrg' || modal === 'updateOrg' ? 'mw-740' : 'share-dailog-box'}
				open={opens}
				handleClose={closeDialog}
				title={
					modal === 'newOrg'
						? t('organisation.new_organisation')
						: modal === 'updateOrg'
						? t('organisation.edit_organisation')
						: ''
				}
				content={
					modal === 'newOrg' ? (
						<AddEditOrg
							fetchMyOrganisation={fetchMyOrganisation}
							// currentPage={currentPage}
							// limit={list}
							modal={modal}
							orgData={organisationData}
							handleClose={closeDialog}
						/>
					) : modal === 'updateOrg' ? (
						<AddEditOrg
							fetchMyOrganisation={fetchMyOrganisation}
							// currentPage={currentPage}
							// limit={list}
							modal={modal}
							orgData={orgData}
							handleClose={closeDialog}
							// searchText={searchText}
						/>
					) : (
						<Share
							fromWhere="user/"
							urlId={fetchFromStorage('authData')?.user_id}
							landType="user"
							handleClose={closeDialog}
						/>
					)
				}
			/>
			{children}
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
	children: PropTypes.node,
};
