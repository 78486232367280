import { useState, useEffect, useRef } from 'react';
import {
	Avatar,
	Box,
	Button,
	Grid,
	Typography,
	Popover,
	List,
	ListItem,
	ListItemText,
	IconButton,
	Tooltip,
	Link,
} from '@material-ui/core';
import { ReactComponent as StaticsUpIcon } from 'src/assets/images/icons/statics-arrow-up.svg';
import { ReactComponent as StaticsDownIcon } from 'src/assets/images/icons/statics-arrow-down.svg';
import { ReactComponent as CommentIcon } from 'src/assets/images/icons/comment.svg';
import { ReactComponent as ReferralsIcon } from 'src/assets/images/icons/referrals.svg';
import { ReactComponent as SharesIcon } from 'src/assets/images/icons/shares.svg';
import { ReactComponent as ReactionsIcon } from 'src/assets/images/icons/reactions.svg';
// import { ReactComponent as DownIcon } from 'src/assets/images/icons/community/down-icon.svg';
import { ReactComponent as VerifiedIcon } from 'src/assets/images/icons/verified.svg';
import { ReactComponent as UnverifiedIcon } from 'src/assets/images/icons/Unverified User.svg';
import { ReactComponent as ReportIcon } from 'src/assets/images/icons/comment-report.svg';
import './community-post.scss';
import _ from 'lodash';
import { useNavigate, useParams, NavLink, useLocation } from 'react-router-dom';
import { DialogBox } from '../DialogBox';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Share } from '../Share';
import { setApiMessage, youTubeVideoCheck } from 'src/helpers/commonFunctions';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { URLS } from 'src/helpers/constants/urls';
import Viewer from 'react-viewer';
import renderHTML from 'react-render-html';
import anchorme from 'anchorme';
import { fetchFromStorage } from 'src/helpers/context';
import { Report } from '../Report';
import { Reactions } from '../Reactions';
import ReactPlayer from 'react-player';
import { parse } from 'query-string';
import { MultiMediaLightBox } from '../MultiMediaLightBox';
import PostComment from './Comment';
import { useTranslation } from 'react-i18next';

export const CommunityPost = ({
	isView,
	isJoin,
	postName,
	time,
	postedBy,
	communityPost,
	topVal,
	downVal,
	contentText,
	contentImg,
	comment,
	shares,
	reactions,
	referrals,
	updateCommunityPost,
	updatePostData,
	handleDeletePost,
	cancelBtnClick,
	data,
	addVote,
	addLike,
	badges,
	user,
	postId,
	communityName,
	postProfile,
	activityType,
	userURI,
	editAccess,
	postUserId,
	allUsersList,
	communityID,
	isPostList,
	communityData,
	closeCommunity,
	postDetails,
	receiver_type,
	linkList,
	matchBtnCondition,
	reactionData,
	likedStatus,
	userPage,
	postVideo,
	postAudio,
	postDoc,
	fetchPostDetails,
	singleItem,
	verifyUser,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const query = parse(location.search);
	const token = fetchFromStorage('authData')?.token;
	const [commentClick, setCommentClick] = useState(false);

	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElReact, setAnchorElReact] = useState(null);
	const [opens, setOpen] = useState(false);
	const [commentData, setCommentData] = useState([]);
	const [reportReasonList, setReportReasonList] = useState([]);
	const [hasMoreItems, setHasMoreItems] = useState(true);
	const [first, setFirst] = useState(1);
	// const [StaticsUp, setStaticsUp] = useState(false);
	// const [staticsDown, setStaticsDown] = useState(false);
	// const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [model, setModel] = useState();
	// const [reaction, setReaction] = useState(false);
	const userId = user?.user_id;
	const { id } = useParams();
	const [list] = useState(5);
	const [reactionList, setReactionList] = useState([]);
	const [recentPage, setCurrentPage] = useState(1);
	const [totalRecord, setTotalRecord] = useState();
	const [tab, setTab] = useState('1');
	const [previousParentId, setPreviousParentPostId] = useState('');
	const [postCommentData, setPostCommentData] = useState([]);
	const [defaultIndex, setDefaultIndex] = useState(0);
	const [visible, setVisible] = useState(false);
	const postContainerRef = useRef(null);

	// const [youTubeVideo] = useState(['https://www.youtube.com/watch?v=tUVDMcd8OE8']);

	const open = Boolean(anchorEl);
	const ids = open ? 'simple-popover' : undefined;

	const openReact = Boolean(anchorElReact);
	const idReact = openReact ? 'simple-popover' : undefined;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const AddReactions = (event) => {
		setAnchorElReact(event.currentTarget);
	};
	const handleCloseReact = () => {
		setAnchorElReact(null);
	};

	const combatDialogHandler = (title) => {
		setOpen(true);
		setModel(title);
	};
	const closeDialog = () => {
		setOpen(false);
	};
	// const handleStaticsup = () => {
	//   StaticsUp ? setStaticsUp(false) : setStaticsUp(true);
	// }
	// const handleStaticDown = () => {
	//   staticsDown ? setStaticsDown(false) : setStaticsDown(true);
	// }
	const scrooTop = () => {
		const anchor = (window.document || document).querySelector('#back-to-top-post');

		if (anchor) {
			anchor.scrollIntoView();
		}
	};

	const fetchReactionsLists = async (postId, likeId, cPage) => {
		var metaLikeId = likeId === 0 || likeId === '' || likeId === undefined ? '' : likeId;

		try {
			const { status, data } = await axiosInstance.get(URLS.getReactionsList + `${postId}`, {
				params: {
					meta_like_id: metaLikeId,
					page: cPage,
					limit: list,
				},
			});

			if (status === 200) {
				setReactionList(data?.reactionData);
				setTotalRecord(data?.totalRecord);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// const theme = createMuiTheme({
	//   overrides: {
	//     MuiTooltip: {
	//       tooltip: {
	//         color: "#ffffff",
	//         backgroundColor: "#438ac1"
	//       }
	//     }
	//   }
	// });
	// FETCH REPORT REASON LIST
	const fetchReportReasonList = async () => {
		try {
			const { status, data } = await axiosInstance.get(URLS.getReportReasonList);

			if (status === 200) {
				setReportReasonList(data?.getReasonList);
			}
		} catch (error) {
			// TODO: Add logging
		}
	};
	// FETCH ALL COMMENT DATA
	const fetchCommentData = async (flag, level, pagenumber) => {
		// page=${first}&limit=5`
		var comment_post_id = location?.state?.previous_parent_id ? location?.state?.previous_parent_id : id;

		try {
			const { status, data } = await axiosInstance.get(
				URLS.getAllComments + comment_post_id,
				{
					params: {
						page: pagenumber && pagenumber !== '' ? pagenumber : first,
						limit: '10',
						...(level && level !== '' && { level: level }),
					},
				},
				// URLS.getAllComments + id
			);

			if (status === 200) {
				const hasMore = !(data?.post_list.length < 10);

				flag && flag !== '' && setFirst(first + 1);
				setHasMoreItems(hasMore);
				// setCommentData(data?.post_list);
				setPreviousParentPostId(data?.previous_parent_post_id);
				pagenumber && pagenumber !== ''
					? setCommentData(data?.post_list)
					: setCommentData([...commentData, ...data?.post_list]);
				// setCommentData(data?.post_list);
			}
		} catch (err) {
			// TODO: Add logging
		}
	};
	// FETCH NOTIFICATION CLICK COMMENT DATA
	const fetchNotificationCommentData = async () => {
		var highlightPostId = location?.state?.post_comment_id
			? location?.state?.post_comment_id
			: query?.highlight_post_id;
		var postId = location?.state?.post_id ? location?.state?.post_id : query?.main_post_id;

		try {
			// const { status, data } = await axiosInstance.get(
			//   URLS.getAllComments + `/${location?.state?.comment_id}`
			//   // URLS.getAllComments + `/${477}`
			// );
			const { status, data } = await axiosInstance.get(URLS.getAllComments + postId, {
				params: {
					page: 1,
					limit: 10,
					...(highlightPostId && { highlight_post_id: highlightPostId }),
				},
				// headers
			});

			if (status === 200) {
				setPostCommentData(data?.post_list);
			}
		} catch (err) {
			// TODO: Add logging
		}
	};

	useEffect(() => {
		location?.state?.post_comment_id && fetchNotificationCommentData();
		query?.highlight_post_id && fetchNotificationCommentData();
	}, []);
	useEffect(() => {
		const commentOpen = isPostList && data?.comments === 'yes';

		setCommentClick(!!commentOpen);
	}, [isPostList, data]);

	// var urlValue = location?.pathname?.split('/');
	const imageurlArray = [];

	contentImg &&
		contentImg.map((msg) => {
			const imageArr = {
				src: msg?.Item?.data,
				downloadUrl: msg?.Item?.data,
			};

			imageurlArray.push(imageArr);

			return msg;
		});
	// const videourlArray = [];
	// postVideo && postVideo.map((msg) => {
	//   const imageArr = {
	//     src: msg?.Item?.data,
	//     downloadUrl: msg?.Item?.data,
	//   };
	//   videourlArray.push(imageArr);
	//   return msg;
	// });
	const renderSwitchForDocumentIcon = (param) => {
		const splitdata = param?.split('.');
		const docurl = splitdata?.[splitdata?.length - 1];

		switch (docurl) {
			case 'pdf':
				return (
					<>
						<PictureAsPdfIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
			case 'txt':
			default:
				return (
					<>
						<InsertDriveFileIcon fontSize="inherit" />
						<Typography className="doc-name">{docurl}</Typography>
					</>
				);
		}
	};
	// PAGINATION FOR ENDORSE USER LIST
	const handlePaginationButtonClicks = async (navDirection) => {
		if (navDirection === 'prev') {
			if (recentPage > 1) {
				setCurrentPage(recentPage - 1);
				fetchReactionsLists(data?.post_id, '', recentPage - 1);
			}
		} else {
			if (recentPage < Math.ceil(totalRecord / list)) {
				setCurrentPage(recentPage + 1);
				fetchReactionsLists(data?.post_id, '', recentPage + 1);
			}
		}
	};
	const handlePaginationClicks = async (event, page) => {
		if (recentPage !== page) {
			setCurrentPage(Number(page));
			fetchReactionsLists(data?.post_id, '', Number(page));
		}
	};
	const tabChangeHandler = (event, newValue) => {
		setTab(newValue);
		const tabValue = newValue - 1;

		setCurrentPage(1);
		fetchReactionsLists(data?.post_id, tabValue, 1);
	};
	const renderMedia = (item) => {
		switch (item?.Item?.type) {
			case 'doc':
				return (
					<Box className="post-image-slider">
						<Box className="message-doc-view">
							<Link href={item?.Item?.data} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.Item?.data)}
							</Link>
						</Box>
					</Box>
				);
			case 'image':
				return (
					<Box className="post-image-slider">
						<Box className="content-img">
							<Avatar src={item?.Item?.data} />
							{/* <LightBox
                imageurl={imageurlArray}
                image={item?.Item?.data}
                otherUserId=""
              /> */}
						</Box>
					</Box>
				);
			case 'video':
				return (
					<Box className="post-image-slider">
						<Box className="post-video-slider">
							<ReactPlayer
								className="react-player"
								url={item?.Item?.data}
								playing={false}
								width="100%"
								height="100%"
								controls={true}
							/>
						</Box>
					</Box>
				);
			case 'audio':
				return (
					<Box className="post-image-slider">
						<ReactPlayer
							className="react-player audio-player"
							url={item?.Item?.data}
							playing={false}
							width="270px"
							height="30px"
							controls={true}
						/>
					</Box>
				);
			default:
				return (
					<Box className="post-image-slider">
						<Box className="message-doc-view">
							<Link href={item?.Item?.data} target="_blank" className="doc-link">
								{renderSwitchForDocumentIcon(item?.Item?.data)}
							</Link>
						</Box>
					</Box>
				);
		}
	};
	const renderMediaHtml = (allMediaList) => {
		switch (String(allMediaList?.length)) {
			case '1':
				return (
					<>
						{allMediaList &&
							allMediaList?.map((media, index) => {
								return (
									<Grid
										key={index + 'renderMediaHtmlCase1'}
										item
										xs={12}
										md={12}
										onClick={() => setDefaultIndex(index)}
									>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
					</>
				);
			case '2':
			case '4':
				return (
					<>
						{allMediaList &&
							allMediaList?.map((media, index) => {
								return (
									<Grid key={index + 'renderMediaHtmlCase4'} item xs={6} md={6} onClick={() => setDefaultIndex(index)}>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
					</>
				);
			case '3':
				return (
					<>
						{allMediaList &&
							allMediaList.map((media, index) => {
								return (
									<Grid key={index + 'renderMediaHtmlCase3'} item xs={4} md={4} onClick={() => setDefaultIndex(index)}>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
					</>
				);
			case '5':
				return (
					<>
						{allMediaList &&
							allMediaList?.slice(0, 2).map((media, index) => {
								return (
									<Grid key={index + 'renderMediaHtmlCase5'} item xs={6} md={6} onClick={() => setDefaultIndex(index)}>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
						{allMediaList &&
							allMediaList?.slice(2, 5).map((media, index) => {
								return (
									<Grid
										key={index + 'renderMediaHtmlCase5/2'}
										item
										xs={4}
										md={4}
										onClick={() => setDefaultIndex(index + 2)}
									>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
					</>
				);
			default:
				return (
					<>
						{allMediaList &&
							allMediaList?.slice(0, 2).map((media, index) => {
								return (
									<Grid
										key={index + 'renderMediaHtmlCaseDefault'}
										item
										xs={6}
										md={6}
										onClick={() => setDefaultIndex(index)}
									>
										<Box className="media-box">{renderMedia(media)}</Box>
									</Grid>
								);
							})}
						{allMediaList &&
							allMediaList?.slice(2, 5).map((media, index) => {
								return (
									<Grid
										key={index + 'renderMediaHtmlCaseDefault2'}
										item
										xs={4}
										md={4}
										onClick={() => setDefaultIndex(index + 2)}
									>
										<Box
											className="media-box"
											style={{
												boxShadow: index === 2 && allMediaList.length > 5 && 'inset 0 0 0 100vw #20202096',
											}}
										>
											{renderMedia(media)}
											{index === 2 && allMediaList.length > 5 && (
												<Typography className="media-count">+{allMediaList.length - 5}</Typography>
											)}
										</Box>
									</Grid>
								);
							})}
					</>
				);
		}
	};
	var allMediaList = data && data?.PostLists?.filter((item) => item?.Item?.type !== 'text');

	var sliderArray = [];

	allMediaList &&
		allMediaList.map((item, index) => {
			var obj = {
				data: item?.Item?.data,
				type: item?.Item?.type,
			};

			sliderArray[index] = obj;
		});

	const redirectToSignUp = () => {
		navigate('/');
	};

	const onShareClick = () => {
		combatDialogHandler('share');
	};

	const onReportClick = () => {
		combatDialogHandler('report');
	};

	const onUpVoteClick = () => {
		if (!token) {
			redirectToSignUp();

			return;
		}

		!closeCommunity && addVote(data, 'vote_up', userPage, singleItem);
	};

	const onDownVoteClick = () => {
		if (!token) {
			redirectToSignUp();

			return;
		}

		!closeCommunity && addVote(data, 'vote_down', userPage, singleItem);
	};

	const onCommentsClick = () => {
		if (!token) {
			redirectToSignUp();

			return;
		}

		isJoin
			? window.location.pathname.includes('/post') && setCommentClick(!commentClick)
			: window.location.pathname.includes('/post') && setApiMessage('error', 'please join community');
	};

	useEffect(() => {
		const checkNavigateInsideApplication = (e) => {
			if (window && e.currentTarget.href.includes(window.location.origin) && e.currentTarget.target !== '_blank') {
				e.stopPropagation();
				e.preventDefault();

				const url = new URL(e.currentTarget.href);

				navigate(url.pathname);
			}

			e.stopPropagation();
		};

		if (postContainerRef.current) {
			const links = postContainerRef.current.querySelectorAll('a');

			links.forEach((link) => {
				link.addEventListener('click', checkNavigateInsideApplication);
			});

			return () => {
				links.forEach((link) => {
					link.removeEventListener('click', checkNavigateInsideApplication);
				});
			};
		}
	}, [contentText]);

	return (
		<>
			<Box className="community-post">
				{/* POST HEADER */}
				<Box className="post-header">
					<Box className="header-left">
						{communityPost ? (
							<>
								<Box className="user-box">
									<>
										<Avatar
											src={postProfile}
											className="user-img"
											style={{ cursor: 'pointer' }}
											onClick={() => {
												setVisible(true);
											}}
										/>
										{postProfile !== '' && postProfile !== undefined && postProfile !== null && (
											<Viewer
												visible={visible}
												onClose={() => {
													setVisible(false);
												}}
												images={[
													{
														src: postProfile,
														title: 'cover image',
													},
												]}
												downloadable
												noFooter={true}
												drag={false}
												zoomable={false}
												rotatable={false}
												scalable={false}
												disableMouseZoom={true}
											/>
										)}
									</>
									<Box className="name-time">
										<Box className="user-name-verification-sec">
											<NavLink to={`/user/${userURI}`} className="user-name">
												{postedBy}
											</NavLink>
											<Box className={'verify-icon-container'}>
												{verifyUser ? <VerifiedIcon /> : <UnverifiedIcon />}
											</Box>
										</Box>
										<Typography className="post-time">{time}</Typography>
									</Box>
									{/* <Typography
                  className="post-title"
                  onClick={() => navigate(`/post/${data?.id}`)}
                >
                  {postName}
                </Typography> */}

									{/* <Typography className="posted-by" onClick={() => navigate(`/user/${userURI}`)}>{postedBy}</Typography> */}
								</Box>
							</>
						) : (
							<>
								<Box className="user-box">
									<>
										<Avatar
											src={postProfile}
											className="user-img"
											style={{ cursor: 'pointer' }}
											onClick={() => {
												setVisible(true);
											}}
										/>
										{postProfile !== '' && postProfile !== undefined && postProfile !== null && (
											<Viewer
												visible={visible}
												onClose={() => {
													setVisible(false);
												}}
												images={[
													{
														src: postProfile,
														title: 'cover image',
													},
												]}
												downloadable
												noFooter={true}
												drag={false}
												zoomable={false}
												rotatable={false}
												scalable={false}
												disableMouseZoom={true}
											/>
										)}
									</>
									<Box className="name-time">
										<Box className="user-name-verification-sec">
											<NavLink
												to={
													receiver_type === 'organisation' ? `/organisation-details/${communityID}` : `/user/${userURI}`
												}
												className="user-name"
											>
												{postedBy}
											</NavLink>
											<Box className={'verify-icon-container'}>
												{receiver_type !== 'organisation' && (verifyUser ? <VerifiedIcon /> : <UnverifiedIcon />)}
											</Box>
										</Box>

										{/* <Typography
                      className="user-name"
                      onClick={() => {
                        receiver_type === 'organisation'
                          ? navigate(`/organisation-details/${communityID}`)
                          : navigate(`/user/${userURI}`);
                      }}
                    >
                      {postedBy}
                    </Typography> */}

										<Typography className="post-time">{time}</Typography>
										{/* <Typography className="posted-by" onClick={() => navigate(`/user/${userURI}`)}>{postedBy}</Typography> */}
									</Box>
								</Box>
								<Typography className="activity-type">
									{receiver_type === 'post' ? '' : t('community.community')}
								</Typography>
								<NavLink
									to={receiver_type === 'organisation' ? `/user/${userURI}` : `/communities/${communityID}`}
									className="community-name"
								>
									{communityName}
								</NavLink>

								{/* <Typography
                  className="community-name"
                  onClick={() => {
                    receiver_type === 'organisation' ? navigate(`/user/${userURI}`)
                      : navigate(`/communities/${communityID}`);
                  }}
                >
                  {communityName}
                </Typography> */}
							</>
						)}
					</Box>
					<Box className="header-right">
						<Box className="stastics-box">
							{/* STASTICS UP VALUE */}
							<Box display="flex" alignItems="center">
								<IconButton className={data?.vote_up_status === 'yes' ? 'fill-blue' : ''} onClick={onUpVoteClick}>
									<StaticsUpIcon />
								</IconButton>
								<Typography className="statics-val up-val">{topVal || 0}</Typography>
							</Box>

							{/* STASTICS DOWN VALUE */}
							<Box display="flex" alignItems="center">
								<IconButton className={data?.vote_down_status === 'yes' ? 'fill-red' : ''} onClick={onDownVoteClick}>
									<StaticsDownIcon className="statics-down-icon" />
								</IconButton>
								<Typography className="statics-val">{downVal || 0}</Typography>
							</Box>
						</Box>

						{/* <Box className="badges-box">
                  {_.times(badges, () => {
                    return <Typography className="badge-avatar" />;
                  })}
                </Box> */}
						<Box
							className="badges-box"
							onClick={() => {
								combatDialogHandler(t('community.reactions'));
								data?.post_id && fetchReactionsLists(data?.post_id, '', recentPage);
							}}
						>
							{data?.MetaLikeLists?.map((item, index) => {
								return <Avatar src={item?.item_data} className="badge-avatar" />;
							})}
						</Box>
					</Box>
				</Box>

				{/* POST CONTENT */}
				<Box className="post-content">
					{communityPost ? (
						<NavLink to={`/post/${data?.id}`} className="post-title">
							{postName}
						</NavLink>
					) : (
						<NavLink to={`/post/${data?.id}`} className="post-title">
							{postName}
						</NavLink>
						// <Typography
						//   className="post-title"
						//   onClick={() => navigate(`/post/${data?.id}`)}
						// >
						//   {postName}
						// </Typography>
					)}
					{contentText ? (
						<Typography className="content-text" ref={postContainerRef}>
							{/* <Typography className="content-text">{contentText}</Typography> */}
							<base href={window.location.origin} />
							{
								// USER PROFILE TYPE ABOUT TEXT
								renderHTML(
									anchorme({
										input: contentText.toString().replace(/\n/g, ''),
										options: {
											attributes: {
												target: '_blank',
												class: 'detected',
											},
											protocol: 'https://',
										},
									}),
								)
							}
						</Typography>
					) : null}
					{linkList &&
						linkList?.map((item) => {
							var checkYTLink = youTubeVideoCheck(item);

							return (
								checkYTLink && (
									<Box style={{ margin: '10px', height: '250px' }}>
										<ReactPlayer
											className="react-player"
											url={item}
											playing={false}
											width="100%"
											height="100%"
											controls={true}
										/>
									</Box>
								)
							);
						})}
					<Grid
						container
						spacing={1}
						// onClick={() => {
						// 	combatDialogHandler('');
						// }}
					>
						{renderMediaHtml(allMediaList)}
					</Grid>
					{/* {contentImg && contentImg.length > 0 ? (
            <Box className="post-image-slider">
              {contentImg && contentImg.map((item, index) => (
                <Box className="content-img">
                  <LightBox
                    imageurl={imageurlArray}
                    image={item?.Item?.data}
                    otherUserId=""
                  />
                </Box>
              ))}
            </Box>
          ) : null} */}
					{/* {
            postVideo && postVideo.length > 0 ? (
              <Box className='post-image-slider'>
                {
                  postVideo && postVideo.map((item, index) => {
                    return (
                      <Box className='post-video-slider'>
                        <ReactPlayer
                          className='react-player'
                          url={item?.Item?.data}
                          playing={false}
                          width='100%'
                          height='100%'
                          controls={true}
                        />
                      </Box>)
                  })}
              </Box>
            ) : null
          } */}
					{/* {
            postAudio && postAudio.length > 0 ? (
              <Box className='post-image-slider'>
                {
                  postAudio && postAudio.map((item, index) => {
                    return (
                      <ReactPlayer
                        className='react-player audio-player'
                        url={item?.Item?.data}
                        playing={false}
                        width='270px'
                        height='30px'
                        controls={true}
                      />
                    );
                  })}
              </Box>
            ) : null
          } */}
					{/* {
            postDoc && postDoc.length > 0 ? (
              <Box className='post-image-slider'>
                {
                  postDoc && postDoc.map((item, index) => {
                    return (
                      <Box className="message-doc-view">
                        <Link href={item?.Item?.data} target="_blank" className='doc-link'>
                          {renderSwitchForDocumentIcon(item?.Item?.data)}
                        </Link>
                      </Box>
                    )
                  })
                }
              </Box>
            ) : null
          } */}
				</Box>
				<Box className="post-actions">
					<Grid container className="post-act-btns">
						{/* COMMENTS */}
						{data?.comments === 'yes' && (
							<Grid item>
								<Button
									disableElevation
									disableRipple
									disableFocusRipple
									disableTouchRipple
									className="act-btn"
									disabled={token && closeCommunity}
									startIcon={<CommentIcon />}
									component={window.location.pathname.includes('/post') ? Button : NavLink}
									to={window.location.pathname.includes('/post') ? null : `/post/${data?.id}`}
									onClick={onCommentsClick}
								>
									{t('community.comment')} ({comment || 0})
								</Button>
							</Grid>
						)}

						{/* SHARES */}
						<Grid item>
							<Button
								variant="contained"
								disableElevation
								disableRipple
								disableFocusRipple
								disableTouchRipple
								className="act-btn"
								disabled={token && closeCommunity}
								startIcon={<SharesIcon />}
								onClick={onShareClick}
							>
								{t('community.shares')} ({shares || 0})
							</Button>
						</Grid>

						{/* REFERRALS */}
						{/* HIDE-MVP: CM-73 */}
						{/*<Grid item>*/}
						{/*	<Button*/}
						{/*		variant="contained"*/}
						{/*		disableElevation*/}
						{/*		disableRipple*/}
						{/*		disableFocusRipple*/}
						{/*		disableTouchRipple*/}
						{/*		className="act-btn"*/}
						{/*		disabled={closeCommunity || !user}*/}
						{/*		startIcon={<ReferralsIcon />}*/}
						{/*	>*/}
						{/*		{t('community.referrals')} ({referrals || 0})*/}
						{/*	</Button>*/}
						{/*</Grid>*/}

						{/* HIDE-MVP: CM-73 */}
						{/* REACTIONS */}
						{/*<Grid item>*/}
						{/*	<Popover*/}
						{/*		className="cr-popover reaction-popover"*/}
						{/*		id={idReact}*/}
						{/*		open={openReact}*/}
						{/*		anchorEl={anchorElReact}*/}
						{/*		onClose={handleCloseReact}*/}
						{/*		anchorOrigin={{*/}
						{/*			vertical: 'top',*/}
						{/*			horizontal: 'left',*/}
						{/*		}}*/}
						{/*		transformOrigin={{*/}
						{/*			vertical: 'bottom',*/}
						{/*			horizontal: 'left',*/}
						{/*		}}*/}
						{/*	>*/}
						{/*		{reactionData?.map((item, index) => {*/}
						{/*			return (*/}
						{/*				<Tooltip title={item?.name} arrow>*/}
						{/*					<IconButton*/}
						{/*						className={item?.id === likedStatus ? 'react-icon react-selected' : 'react-icon'}*/}
						{/*						onClick={() => {*/}
						{/*							if (isJoin) {*/}
						{/*								addLike(data, item?.id, userPage, singleItem);*/}
						{/*								setAnchorElReact(null);*/}
						{/*							}*/}
						{/*						}}*/}
						{/*					>*/}
						{/*						<Avatar src={item?.item_data} className="reaction-img" />*/}
						{/*					</IconButton>*/}
						{/*				</Tooltip>*/}
						{/*			);*/}
						{/*		})}*/}
						{/* <MuiThemeProvider theme={theme}>
                  <Tooltip title={t('reactions.shaka')} arrow>
                    <IconButton className="react-icon">
                      <ShakaIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('reactions.fist_bump')} arrow>
                    <IconButton className="react-icon">
                      <FistBumpIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('reactions.oss')} arrow>
                    <IconButton className="react-icon">
                      <OSSIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('reactions.bow')} arrow>
                    <IconButton className="react-icon">
                      <BOWIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('reactions.dab')} arrow>
                    <IconButton className="react-icon">
                      <DABIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('reactions.pak_mei')} arrow>
                    <IconButton className="react-icon">
                      <PakMeiIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('reactions.pak_mei_salute')} arrow>
                    <IconButton className="react-icon">
                      <PakMeiSaluteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('reactions.wai')} arrow>
                    <IconButton className="react-icon">
                      <WaiIcon />
                    </IconButton>
                  </Tooltip>
                </MuiThemeProvider> */}
						{/* HIDE-MVP: CM-73 */}
						{/*	</Popover>*/}
						{/*	<Button*/}
						{/*		variant="contained"*/}
						{/*		disableElevation*/}
						{/*		disableRipple*/}
						{/*		disableFocusRipple*/}
						{/*		disableTouchRipple*/}
						{/*		disabled={closeCommunity || !user}*/}
						{/*		className="act-btn"*/}
						{/*		startIcon={<ReactionsIcon />}*/}
						{/*		onClick={(e) => (isJoin ? AddReactions(e) : setApiMessage('error', t('community.join_message')))}*/}
						{/*		// onClick={() => {*/}
						{/*		//   combatDialogHandler(t('community.reactions'));*/}
						{/*		// }}*/}
						{/*	>*/}
						{/*		{t('community.reactions')} ({reactions || 0})*/}
						{/*	</Button>*/}
						{/*</Grid>*/}
						{postDetails && postDetails ? (
							<Grid item>
								<Button
									variant="contained"
									disableElevation
									disableRipple
									disableFocusRipple
									disableTouchRipple
									className="act-btn"
									disabled={token && closeCommunity}
									startIcon={<ReportIcon />}
									onClick={onReportClick}
								>
									{t('report')}
								</Button>
							</Grid>
						) : (
							<></>
						)}
					</Grid>

					{editAccess || postUserId === userId || user?.isAdmin ? (
						<>
							<Popover
								className="admin-tools-popover"
								id={ids}
								open={open}
								anchorEl={anchorEl}
								onClose={handleClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
							>
								<Box className="admin-tools">
									<List>
										{editAccess || user?.isAdmin ? (
											<ListItem>
												<ListItemText
													onClick={() => {
														updateCommunityPost(data, data?.sticky === 'yes' ? 'no' : 'yes');
														handleClose();
													}}
												>
													{data?.sticky === 'yes' ? t('community.remove_from_sp') : t('community.sticky_post')}
												</ListItemText>
											</ListItem>
										) : (
											<></>
										)}
										{receiver_type !== 'user' && (
											<ListItem>
												<ListItemText
													onClick={() => {
														handleClose();
														updatePostData(data);
														setTimeout(() => {
															scrooTop();
														}, 600);
													}}
												>
													{t('community.edit_post')}
												</ListItemText>
											</ListItem>
										)}
										<ListItem
											onClick={() => {
												handleDeletePost(data);
												handleClose();
											}}
										>
											<ListItemText>{t('community.delete_post')}</ListItemText>
										</ListItem>
									</List>
								</Box>
							</Popover>
							{matchBtnCondition === 'no' ? (
								''
							) : (
								<Box className="admin-box">
									{/* {urlValue && urlValue[1] === 'news-feed' && ( */}
									{(editAccess || postUserId === userId || user?.isAdmin) && (
										<Button
											variant="contained"
											fullWidth
											disableElevation
											disableRipple
											disableTouchRipple
											disableFocusRipple
											className="admin-btn"
											disabled={closeCommunity || !user}
											onClick={(e) => {
												handleClick(e);
												cancelBtnClick();
											}}
											// endIcon={<DownIcon />}
										>
											{user?.isAdmin ? t('community.admin') : t('community.manage_post')}
										</Button>
									)}
								</Box>
							)}
						</>
					) : null}
				</Box>
			</Box>
			{commentClick && !closeCommunity ? (
				<>
					<PostComment
						fetchPostDetails={fetchPostDetails}
						fetchNotificationCommentData={fetchNotificationCommentData}
						postId={data?.id}
						isView={isView}
						commentData={commentData}
						setCommentData={setCommentData}
						reportReasonList={reportReasonList}
						fetchReportReasonList={fetchReportReasonList}
						fetchCommentData={fetchCommentData}
						allUsersList={allUsersList}
						hasMoreItems={hasMoreItems}
						user={user}
						isJoin={
							!(
								fetchFromStorage('authData')?.user_id === communityData?.user_owner_id ||
								communityData?.join_status === 'accepted' ||
								data?.receiver_type === 'post' ||
								data?.receiver_type === 'user' ||
								data?.receiver_type === 'organisation'
							)
						}
						notificationCmt={location?.state?.comment_id}
						navigate={navigate}
						postCommentData={postCommentData}
						previousParentId={previousParentId}
					/>
				</>
			) : null}
			<DialogBox
				className={
					model === 'share'
						? 'share-dailog-box'
						: model === 'Reactions'
						? 'reaction-dialog-box'
						: model === ''
						? 'media-dialog-box'
						: ''
				}
				open={opens}
				handleClose={closeDialog}
				title={model === 'report' ? t('report') : model === t('community.reactions') ? t('community.reactions') : ''}
				content={
					model === 'share' ? (
						<Share
							fetchPostDetails={fetchPostDetails}
							fromWhere="post/"
							urlId={data?.id}
							landType="post"
							handleClose={closeDialog}
						/>
					) : model === 'report' ? (
						<Report handleClose={closeDialog} reportReasonList={reportReasonList} type="post" receiverId={data?.id} />
					) : model === t('community.reactions') ? (
						<Reactions
							reactionList={reactionList}
							fetchReactionsLists={fetchReactionsLists}
							postId={data?.post_id}
							handlePaginationButtonClicks={handlePaginationButtonClicks}
							handlePaginationClicks={handlePaginationClicks}
							totalRecord={totalRecord}
							list={list}
							recentPage={recentPage}
							tabChangeHandler={tabChangeHandler}
							tab={tab}
						/>
					) : model === '' ? (
						<MultiMediaLightBox mediArray={sliderArray} defaultIndex={defaultIndex} />
					) : (
						<></>
					)
				}
			/>
		</>
	);
};
