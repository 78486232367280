export default [
	{ href: '/admin/users', title: 'top_menu_options.users' },
	{ href: '/admin/communities', title: 'top_menu_options.communities' },
	{ href: '/admin/badge', title: 'myprofile.badges' },
	{ href: '/admin/fight', title: 'events.fight' },
	// HIDE-MVP: CM-74
	// { href: '/admin/events', title: 'top_menu_options.events' },
	// HIDE-MVP: CM-76
	// { href: '/admin/messages', title: 'top_menu_options.messages' },
	// { href: '/admin/connections', title: ''myprofile.connections' },
	{ href: '/news-feed', title: 'top_menu_options.newsfeed' },
	{ href: '/search', title: 'search.title' },
	{ href: '/notification', title: 'top_menu_options.notifications' },
];
