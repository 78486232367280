import { Navigate } from 'react-router-dom';

import { AuthLayout, MainLayout, SignupProfileDetailsLayout, DashboardLayout } from 'src/layouts';

import {
	AccountSettings,
	UserConnection,
	NewsFeed,
	Faq,
	AllCommunityDirectory,
	AllBadges,
	CommunityView,
	// ClaimProfile,
	CommunityList,
	CommunityPostView,
	Followers,
	SeeAllActivity,
	SeeFightRecord,
	// Events,
	Following,
	ViewAllBadges,
	// FightRecord,
	// Messages,
	// Organization,
	// Search,
	ShadowProfile,
	Notification,
	MobileViewFilter,
	UserProfile,
	UserNewsFeed,
} from 'src/pages/application';

import { Login, ResetPassword, ForgotPassword, Profile, Register, VerifyOTP } from 'src/pages/auth';

import {
	Fight as AdminFight,
	Users as AdminUsers,
	Badge as AdminBadge,
	Dashboard as AdminDashboard,
	ManageUser as AdminManageUser,
} from 'src/pages/admin';
import Referrals from './pages/application/Referrals/Referrals';

// Common Public Routes for user profile etc...
const publicData = {
	path: '/',
	element: <MainLayout />,
	children: [
		{ path: '/user/:id', element: <UserProfile /> },
		{ path: '/user/shadow/:id', element: <ShadowProfile /> },
		{ path: '/connections/:type', element: <UserConnection /> },
		// { path: '/users', element: <UsersPage /> },
		{ path: '/post/:id', element: <CommunityPostView /> },
		{ path: '/communities/:id', element: <CommunityView /> },
		{ path: '/communities/communities/:id', element: <CommunityView /> },
		{ path: '*', element: <Navigate to="/" /> },
	],
};

// authenticatedRoute
const authenticateData = {
	path: '/',
	element: <MainLayout />,
	children: [
		{ path: '/', element: <Navigate to="/news-feed" /> },
		{ path: '/news-feed', element: <NewsFeed /> },
		{ path: '/news-feed/:id', element: <UserNewsFeed /> },
		{ path: '/communities', element: <CommunityList /> },
		{ path: '/community-directory', element: <AllCommunityDirectory /> },
		{ path: '/communities/mobile-view', element: <MobileViewFilter /> },
		// { path: '/new-event', element: <NewEvents /> },
		// HIDE-MVP: CM-74
		// { path: '/events/:type', element: <Events /> },
		// { path: '/events/details/:id', element: <EventDetailsPage /> },
		// HIDE-MVP: CM-76
		// { path: '/messages/:id', element: <Messages /> },
		// { path: '/messages', element: <UserMessages /> },
		// { path: '/connections/:type', element: <UserConnection /> },
		{ path: '/users', element: <UserConnection /> },
		//  HIDE-MVP: CM-72
		// { path: '/claim-profile', element: <ClaimProfile /> },
		// { path: '/fight-record', element: <FightRecordPage /> },
		{ path: '/see-fight-record', element: <SeeFightRecord /> },
		{ path: '/followers', element: <Followers /> },
		{ path: '/following', element: <Following /> },
		{ path: '/notification', element: <Notification /> },
		// HIDE-MVP: CM-78
		// { path: '/search', element: <SearchView /> },
		// HIDE-MVP: CM-79
		// { path: '/organisation-details/:id', element: <OrganisationPage /> },
		// { path: '/organisation-user/:type', element: <OrgSocialActivity /> },
		// { path: '/your-organisation', element: <YourOrganisation /> },
		// { path: '/create-organisation', element: <AddEditOrg /> },
		{ path: '/allbadge', element: <AllBadges /> },
		{ path: '/viewallbadges', element: <ViewAllBadges /> },
		{ path: '/faq', element: <Faq /> },
		{ path: '/referral-code', element: <Referrals /> },
		{ path: '/Account-settings', element: <AccountSettings /> },
		{ path: '/all-activity', element: <SeeAllActivity /> },
		{ path: '*', element: <Navigate to="/news-feed" /> },
	],
};

// Authenticated App Routes
export const authRoute = [authenticateData, publicData];

// Signup Profile Details Route
export const profileRoute = [
	{
		path: '/',
		element: <SignupProfileDetailsLayout home="/profile/details" />,
		children: [
			{ path: '/', element: <Navigate to="/profile/details" /> },
			{ path: '/profile/:type', element: <Profile /> },
			{ path: '*', element: <Navigate to="/profile/details" /> },
		],
	},
];

// Public Routes
export const publicRoute = [
	{
		path: '/',
		element: <AuthLayout />,
		children: [
			{ path: '/', element: <Navigate to="/sign-up" /> },
			{ path: '/sign-in', element: <Login /> },
			{ path: '/sign-up', element: <Register /> },
			{ path: '/verify-otp', element: <VerifyOTP /> },
			{ path: '/forgot-password', element: <ForgotPassword /> },
			{ path: '/reset-password', element: <ResetPassword /> },
			{ path: '*', element: <Navigate to="/" /> },
		],
	},
	publicData,
];

// Admin Dashboard's Routes
export const adminRoutes = [
	authenticateData,
	{
		path: '/admin',
		element: <DashboardLayout />,
		children: [
			{ path: '', element: <Navigate to="dashboard" /> },
			{ path: 'dashboard', element: <AdminDashboard /> },
			{ path: 'users', element: <AdminUsers /> },
			{ path: 'users/:id', element: <AdminManageUser /> },
			{ path: 'badge', element: <AdminBadge /> },
			{ path: 'fight', element: <AdminFight /> },
			{ path: '*', element: <Navigate to="dashboard" /> },
		],
	},
	publicData,
];
