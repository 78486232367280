export const preparePostListForRequest = (allImages, rawHTML) => {
	const postList = allImages.map((item, i) => {
		return {
			type: item?.type,
			item_id: item?.id,
		};
	});

	postList.push({
		type: 'text',
		data: rawHTML,
	});

	return postList;
};
